import { type IconSvgProps } from './types'

export function LoadingIcon(props: IconSvgProps) {
  return (
    <svg
      height="1em"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <rect fill="#ed6103" height="15" width="15" x="17.5" y="17.5">
        <animate
          attributeName="fill"
          begin="0s"
          calcMode="discrete"
          dur="1s"
          keyTimes="0;0.125;1"
          repeatCount="indefinite"
          values="#f8b26a;#ed6103;#ed6103"
        />
      </rect>
      <rect fill="#ed6103" height="15" width="15" x="42.5" y="17.5">
        <animate
          attributeName="fill"
          begin="0.125s"
          calcMode="discrete"
          dur="1s"
          keyTimes="0;0.125;1"
          repeatCount="indefinite"
          values="#f8b26a;#ed6103;#ed6103"
        />
      </rect>
      <rect fill="#ed6103" height="15" width="15" x="67.5" y="17.5">
        <animate
          attributeName="fill"
          begin="0.25s"
          calcMode="discrete"
          dur="1s"
          keyTimes="0;0.125;1"
          repeatCount="indefinite"
          values="#f8b26a;#ed6103;#ed6103"
        />
      </rect>
      <rect fill="#ed6103" height="15" width="15" x="17.5" y="42.5">
        <animate
          attributeName="fill"
          begin="0.875s"
          calcMode="discrete"
          dur="1s"
          keyTimes="0;0.125;1"
          repeatCount="indefinite"
          values="#f8b26a;#ed6103;#ed6103"
        />
      </rect>
      <rect fill="#ed6103" height="15" width="15" x="67.5" y="42.5">
        <animate
          attributeName="fill"
          begin="0.375s"
          calcMode="discrete"
          dur="1s"
          keyTimes="0;0.125;1"
          repeatCount="indefinite"
          values="#f8b26a;#ed6103;#ed6103"
        />
      </rect>
      <rect fill="#ed6103" height="15" width="15" x="17.5" y="67.5">
        <animate
          attributeName="fill"
          begin="0.75s"
          calcMode="discrete"
          dur="1s"
          keyTimes="0;0.125;1"
          repeatCount="indefinite"
          values="#f8b26a;#ed6103;#ed6103"
        />
      </rect>
      <rect fill="#ed6103" height="15" width="15" x="42.5" y="67.5">
        <animate
          attributeName="fill"
          begin="0.625s"
          calcMode="discrete"
          dur="1s"
          keyTimes="0;0.125;1"
          repeatCount="indefinite"
          values="#f8b26a;#ed6103;#ed6103"
        />
      </rect>
      <rect fill="#ed6103" height="15" width="15" x="67.5" y="67.5">
        <animate
          attributeName="fill"
          begin="0.5s"
          calcMode="discrete"
          dur="1s"
          keyTimes="0;0.125;1"
          repeatCount="indefinite"
          values="#f8b26a;#ed6103;#ed6103"
        />
      </rect>
    </svg>
  )
}
