import { type IconSvgProps } from './types'

export function FileIcon(props: IconSvgProps) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 16 17"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_92_516)">
        <path d="M11.5682 12.8181H4.43182V13.5H11.5682V12.8181Z" fill="currentColor" />
        <path d="M11.5682 10.0527H4.43182V10.7346H11.5682V10.0527Z" fill="currentColor" />
        <path d="M11.5682 7.2876H4.43182V7.96969H11.5682V7.2876Z" fill="currentColor" />
        <path d="M11.5682 4.52271H8.20459V5.20458H11.5682V4.52271Z" fill="currentColor" />
        <path
          d="M12.8633 0.5H6.9612C6.53748 0.5 6.13107 0.668437 5.83145 0.967781L2.14982 4.64962C1.85014 4.94922 1.68182 5.35575 1.68182 5.7795V15.0451C1.68182 15.8473 2.3346 16.5 3.13682 16.5H12.8633C13.6654 16.5 14.3182 15.8473 14.3182 15.0451V1.95444C14.3182 1.15244 13.6654 0.5 12.8633 0.5ZM6.44539 1.51091V4.78128C6.44539 5.13481 6.30773 5.27259 5.9542 5.27259H2.68367L6.44539 1.51091ZM13.5001 15.0451C13.5001 15.3963 13.2143 15.6818 12.8633 15.6818H3.13682C2.78567 15.6818 2.49995 15.3963 2.49995 15.0451V5.99994H6.39226C6.82257 5.99994 7.17282 5.64972 7.17282 5.21916V1.31825H12.8633C13.2143 1.31825 13.5001 1.60347 13.5001 1.95444V15.0451Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_92_516">
          <rect fill="white" height="16" transform="translate(0 0.5)" width="16" />
        </clipPath>
      </defs>
    </svg>
  )
}
