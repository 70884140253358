import { forwardRef } from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { type NonNullableProps } from '../../lib/types'
import { tw } from '../../lib/utils'

const buttonVariants = cva(
  'box-border inline-flex select-none items-center justify-center gap-2.5 whitespace-nowrap rounded-base font-bold transition-colors focus-visible:outline-none',
  {
    variants: {
      variant: {
        default: 'border border-[#9f6d40] bg-brand text-brand-foreground hover:bg-brand-hovered',
        secondary: 'border border-[#636363] bg-[#a5a5a5] text-white hover:bg-darkgray',
        info: 'bg-info text-info-foreground hover:bg-info/90',
        outline:
          'border border-neutral-foreground bg-white hover:bg-accent hover:text-accent-foreground',
        dashed:
          'border-2 border-dashed border-neutral-foreground bg-white hover:bg-accent hover:text-accent-foreground',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        text: 'rounded-none border-none p-0 text-accent-foreground',
        link: 'border-none !p-0 text-base font-normal text-brand hover:cursor-pointer hover:underline',
      },
      flat: {
        true: 'border-none shadow-none',
      },
      block: {
        true: 'w-full',
      },
      size: {
        small: 'h-12.5 w-30 px-5 py-1 text-base leading-normal',
        medium: 'h-[3.4375rem] w-50 px-5 py-2.5 text-base leading-normal',
        large: 'h-[3.9375rem] px-10 py-2.5 text-base leading-normal',
      },
      disabled: {
        true: 'pointer-events-none border-[#ddd] bg-[#ddd] text-white shadow-[0px_1px_0px_#ddd]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'medium',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    NonNullableProps<VariantProps<typeof buttonVariants>> {
  asChild?: boolean
  loading?: boolean
  /**
   * @deprecated
   */
  pilled?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      block,
      size,
      asChild = false,
      disabled,
      loading,
      type = 'button',
      pilled,
      flat,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={tw.merge(buttonVariants({ variant, block, size, disabled, flat, className }))}
        disabled={disabled || loading}
        ref={ref}
        type={type}
        {...props}
      />
    )
  }
)
Button.displayName = 'Button'

export { Button }
