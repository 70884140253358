'use client'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { type Auth, signOut } from 'firebase/auth'
import { t } from 'i18next'
import { useRouter } from 'next/navigation'

import { client } from '@/lib/api'
import { ROUTES } from '@/lib/constants/routes'
import { errorHandler } from '@/utils/errors'

import { useHandleAuthState } from './useHandleAuthState'

const REVOKE_REFRESH_TOKEN = '/accounts/{uid}/refreshtokens'

// リフレッシュトークン取り消し
export function useRevokeRefreshToken(auth: Auth) {
  const router = useRouter()
  const { reset } = useHandleAuthState()
  const queryClient = useQueryClient()

  const { mutate, isPending, isError } = useMutation({
    mutationFn: (uid: string) => {
      return client.DELETE(REVOKE_REFRESH_TOKEN, { params: { path: { uid } } })
    },
    onSuccess: async () => {
      await signOut(auth)
      reset()
      // キャッシュ削除
      queryClient.clear()
      //リフレッシュトークン更新用にページリフレッシュ
      router.replace(ROUTES.LOGIN)
      window.location.reload()
    },
    onError: (error) => {
      errorHandler({ error })
    },
  })

  return {
    signOut: () => {
      const { uid = '' } = auth.currentUser ?? {}
      // uidが取得できない場合は、エラーを表示する
      if (uid === '') {
        errorHandler({ error: new Error(t('messages.signOutFailed')) })
      }
      mutate(uid)
    },
    isPending,
    isError,
  }
}
