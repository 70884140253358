import { forwardRef } from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import { cva, type VariantProps } from 'class-variance-authority'

import { tw } from '@/lib/utils'

const labelVariants = cva(
  'flex select-none items-center gap-3 text-xl font-bold text-input-label peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
  {
    variants: {
      density: {
        default: 'leading-input',
        compact: 'leading-9',
      },
    },
    defaultVariants: { density: 'default' },
  }
)

const Label = forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, density, title, ...props }, ref) => {
  if (props.asChild) {
    return (
      <LabelPrimitive.Root
        className={tw.merge(labelVariants({ density }), className)}
        ref={ref}
        {...props}
      />
    )
  }
  return (
    <LabelPrimitive.Root
      className={tw.merge(labelVariants({ density }), className)}
      ref={ref}
      {...props}
    >
      {props.children}
      {/* {title ? <LabelTooltip content={title} /> : null} */}
    </LabelPrimitive.Root>
  )
})
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
