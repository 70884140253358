import { type IconSvgProps } from './types'

export function NoticeIcon(props: IconSvgProps) {
  return (
    <svg
      {...props}
      className="mt-1 block size-5"
      fill="none"
      viewBox="0 0 20 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 15.9985L9.5 1.4985H10.5L19 15.4985L18.5 17.4985H2.5L1 16.9985V15.9985Z"
        fill="currentColor"
      />
      <path
        d="M10.0004 13.4516C9.3448 13.4516 8.8125 13.9834 8.8125 14.639C8.8125 15.2949 9.3448 15.8267 10.0004 15.8267C10.6561 15.8267 11.1878 15.2949 11.1878 14.639C11.1879 13.9834 10.6561 13.4516 10.0004 13.4516Z"
        fill="white"
      />
      <path
        d="M19.7304 15.4031L11.7488 1.57847C11.3893 0.955653 10.7193 0.568817 9.9997 0.568817C9.28067 0.568817 8.61126 0.955692 8.25177 1.57847L0.269619 15.4025C-0.089873 16.0253 -0.089873 16.799 0.269619 17.4218C0.62915 18.0446 1.29915 18.4312 2.01817 18.4312H17.9818C18.7008 18.4312 19.3709 18.0446 19.7304 17.4218C20.0899 16.799 20.0899 16.0253 19.7304 15.4031ZM18.1432 16.3908C18.0279 16.5901 17.8133 16.7137 17.5826 16.7137H2.41712C2.18696 16.7137 1.97204 16.5901 1.85739 16.3902C1.74161 16.1906 1.74103 15.9432 1.85685 15.7438L9.43974 2.60944C9.5547 2.41011 9.76927 2.28624 10.0003 2.28624C10.2308 2.28624 10.445 2.41011 10.5603 2.60975L18.1432 15.7436C18.2584 15.9432 18.2584 16.1912 18.1432 16.3908Z"
        fill="currentColor"
      />
      <path
        d="M10.0004 5.46725C9.3448 5.46725 8.8125 5.99901 8.8125 6.65495L9.26875 12.0255C9.26875 12.4297 9.59602 12.7569 10.0004 12.7569C10.4043 12.7569 10.7321 12.4297 10.7321 12.0255L11.1878 6.65495C11.1879 5.99897 10.6561 5.46725 10.0004 5.46725Z"
        fill="white"
      />
    </svg>
  )
}
