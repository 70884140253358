import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef } from 'react'

import { cn } from '../../lib/index'
import { type NonNullableProps } from '../../lib/types'

const typographyVariants = cva(cn`tracking-wider text-neutral-foreground`, {
  variants: {
    size: {
      xxs: cn`text-xxs`,
      xs: cn`text-xs`,
      sm: cn`text-sm`,
      md: cn`text-base`,
      lg: cn`text-lg`,
      xl: cn`text-xl`,
      '2xl': cn`text-2xl`,
    },
    weight: {
      thin: cn`font-thin`,
      extralight: cn`font-extralight`,
      light: cn`font-light`,
      normal: cn`font-normal`,
      medium: cn`font-medium`,
      semibold: cn`font-semibold`,
      bold: cn`font-bold`,
      extrabold: cn`font-extrabold`,
      black: cn`font-black`,
    },
    align: {
      left: cn`text-left`,
      center: cn`text-center`,
      right: cn`text-right`,
    },
    transform: {
      capitalize: cn`capitalize`,
      uppercase: cn`uppercase`,
      lowercase: cn`lowercase`,
    },
    wrap: { false: cn`whitespace-nowrap` },
    truncate: { true: cn`truncate` },
    monospace: { true: cn`font-mono` },
  },
})

type TypographyVariants = NonNullableProps<VariantProps<typeof typographyVariants>>

type TypographyTagName =
  | 'blockquote'
  | 'div'
  | 'dt'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'legend'
  | 'p'
  | 'q'
  | 'small'
  | 'span'
  | 'strong'
  | 'label'

export type TypographyProps<T extends TypographyTagName> = {
  as?: T
  asChild?: boolean
  children?: React.ReactNode
  className?: string
} & React.ComponentPropsWithoutRef<T> &
  TypographyVariants

const Typography = forwardRef<HTMLElement, TypographyProps<TypographyTagName>>(
  (
    {
      as,
      children,
      className,
      size,
      weight = 'normal',
      align,
      transform,
      truncate = false,
      monospace = false,
      wrap = true,
      asChild,
      ...restProps
    },
    ref
  ) => {
    const Component = as ? as : asChild ? Slot : 'p'
    return (
      <Component
        ref={ref}
        {...(restProps as any)}
        className={cn(
          typographyVariants({ size, weight, align, transform, wrap, truncate, monospace }),
          className
        )}
      >
        {children}
      </Component>
    )
  }
)
Typography.displayName = 'Typography'

export { Typography }
