/**
 * Do not edit directly
 * Generated on Wed, 19 Jun 2024 07:00:48 GMT
 */

export const ColorNeutralDefault = '#f7f9f7'
export const ColorNeutralForeground = '#172b4d'
export const ColorPrimaryDefault = '#ed6103'
export const ColorPrimaryForeground = '#ffffff'
export const ColorSecondaryDefault = '#a2a2a2'
export const ColorSecondaryForeground = '#ffffff'
export const ColorAccentDefault = '#f2f2f2'
export const ColorAccentForeground = '#172b4d'
export const ColorMutedDefault = '#d8d8d8'
export const ColorMutedForeground = '#999999'
export const ColorBrandDefault = '#e26c0a'
export const ColorBrandForeground = '#ffffff'
export const ColorBrandHovered = '#cc630d'
export const ColorInfoDefault = '#0066cc'
export const ColorInfoForeground = '#ffffff'
export const ColorWarningDefault = '#ffff00'
export const ColorWarningForeground = '#000000'
export const ColorDangerDefault = '#ff0000'
export const ColorDangerForeground = '#ffffff'
export const ColorInputDefault = '#333333'
export const ColorInputPlaceholder = '#8b8b8b'
export const ColorInputBorder = '#c0c0c0'
export const ColorInputError = '#fcf1e7'
export const ColorInputDisabled = '#dddddd'
export const ColorInputLabel = '#3c3c3c'
export const ZIndexOverlay = 1200
export const ZIndexModal = 1300
export const ZIndexToast = 1400
export const ZIndexPopover = 1500
export const ZIndexTooltip = 1600
