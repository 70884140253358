'use client'

import type React from 'react'
import { type Dispatch } from 'react'
import { Check, ChevronsUpDown } from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { cn } from '@/lib/utils'

export interface Option {
  label: string
  value: string
}

interface OpenHandler {
  open: boolean
  onOpenChangeHandler: Dispatch<React.SetStateAction<boolean>>
}

interface ValueHandler {
  selectedValue: string
  onValueChange: Dispatch<React.SetStateAction<string>>
}

interface Props {
  options: Option[]
  openHandler: OpenHandler
  valueHandler: ValueHandler
  selectPlaceholder?: string
  inputPlaceholder?: string
  emptyPlaceholder?: string
  className?: string
  onSelect?: (currentValue: string) => void
  disabled: boolean
}

export function Combobox({
  className,
  options = [],
  openHandler,
  valueHandler,
  inputPlaceholder = '入力してください',
  selectPlaceholder = '選択してください',
  emptyPlaceholder = 'ありません',
  onSelect,
  disabled = false,
}: Props) {
  const { open, onOpenChangeHandler } = openHandler
  const { selectedValue, onValueChange } = valueHandler

  return (
    <Popover
      aria-disabled={disabled}
      onOpenChange={onOpenChangeHandler}
      open={disabled ? false : open}
    >
      <PopoverTrigger asChild>
        <Button
          aria-expanded={open}
          className={cn(
            'h-[63px] w-full justify-between font-normal border border-[#b8b8b8]',
            disabled && 'bg-input-disabled cursor-not-allowed',
            className
          )}
          role="combobox"
          variant="outline"
        >
          <span className="truncate">
            {selectedValue || ''
              ? options?.find((option) => option?.value === selectedValue)?.label
              : selectPlaceholder}
          </span>
          <ChevronsUpDown className="ml-2 size-4 w-6 shrink opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[500px] p-0">
        <Command
          filter={(_, search, keywords = []) => {
            const keyword = keywords[0]
            if (keyword.includes(search)) return 1
            return 0
          }}
        >
          <CommandInput disabled={disabled} placeholder={inputPlaceholder} />
          <CommandEmpty>{emptyPlaceholder}</CommandEmpty>
          <CommandGroup>
            <CommandList>
              {options?.map((option) => (
                <CommandItem
                  aria-disabled={disabled}
                  className={cn(
                    selectedValue === option.value &&
                      'bg-brand text-brand-foreground aria-selected:bg-brand aria-selected:text-brand-foreground'
                  )}
                  key={option.value}
                  keywords={[option.label]}
                  onSelect={
                    onSelect
                      ? onSelect
                      : (currentValue) => {
                          onValueChange(currentValue === selectedValue ? '' : currentValue)
                          onOpenChangeHandler(false)
                        }
                  }
                  value={option.value}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      selectedValue === option.value ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandList>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
