import { type IconSvgProps } from './types'

export function CheckCircleIcon(props: IconSvgProps) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 20 20"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99956 0C4.47611 0 0 4.47699 0 10.0004C0 15.523 4.47611 20 9.99956 20C15.5221 20 19.9991 15.523 19.9991 10.0004C19.9991 4.47699 15.5221 0 9.99956 0ZM9.43252 14.6714L4.65188 10.8466L6.46593 8.57887L8.94679 10.5639L13.1264 5.19049L15.4199 6.97345L9.43252 14.6714Z"
        fill="currentColor"
      />
    </svg>
  )
}
