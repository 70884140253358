'use client'

import { forwardRef, type HTMLAttributes } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn, type NonNullableProps } from '../../lib'

const separatorVariants = cva('border border-[#666666]', {
  variants: {
    variant: {
      default: ['border-solid'],
      dashed: ['border-dashed'],
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

interface DivProps
  extends HTMLAttributes<HTMLDivElement>,
    NonNullableProps<VariantProps<typeof separatorVariants>> {}

const Separator = forwardRef<HTMLDivElement, DivProps>(({ className, variant, ...props }, ref) => {
  return <div className={cn(separatorVariants({ variant }), className)} ref={ref} {...props} />
})

Separator.displayName = 'Separator'

export { Separator }
