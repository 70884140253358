'use client'

import {
  Select as ShadSelect,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'

export interface SelectOption {
  label: string
  value: any
}

interface Props {
  placeholder?: string
  options: SelectOption[]
  className?: string
  value?: any
  defaultValue?: any
  onValueChange: (v: any) => void
  disabled?: boolean
}

// TODO select要素の型を公開するようにする
export function Select({
  placeholder = '',
  options = [],
  className = '',
  value = '',
  defaultValue = '',
  onValueChange,
  disabled,
}: Props): JSX.Element {
  return (
    <ShadSelect
      defaultValue={defaultValue}
      disabled={disabled}
      onValueChange={onValueChange}
      value={value}
    >
      <SelectTrigger className={cn(className)}>
        {/* TODO 色を直接指定している */}
        <SelectValue placeholder={<span className="text-[#8B8B8B]">{placeholder}</span>} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {(options?.length ?? 0) != 0 &&
            options.map(({ label, value }) => {
              return (
                <SelectItem key={label} value={value}>
                  {label}
                </SelectItem>
              )
            })}
        </SelectGroup>
      </SelectContent>
    </ShadSelect>
  )
}
