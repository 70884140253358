'use client'

import { forwardRef, type ReactNode, useId } from 'react'

import { Flex, Typography } from '@/components'
import { cn } from '@/lib'

export interface CheckboxProps extends React.ComponentPropsWithoutRef<'input'> {
  label?: ReactNode
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, children, className, id, ...props }, ref) => {
    const randomId = useId()
    const checkboxId = id || randomId
    return (
      <Flex align="center" className={cn('h-6 gap-1.5', className)}>
        <input
          {...props}
          className="aria-[invalid=true]:border-input-border aria-[invalid=true]:bg-input-error"
          id={checkboxId}
          ref={ref}
          type="checkbox"
        />
        <Typography asChild className="select-none font-bold leading-none" size="sm">
          {label || children ? <label htmlFor={checkboxId}>{label}</label> : null}
        </Typography>
      </Flex>
    )
  }
)
Checkbox.displayName = 'Checkbox'

export { Checkbox }
