'use client'

import { createPortal } from 'react-dom'

import { ZIndexOverlay } from '../../lib/constants/design-tokens'
import { Flex } from '../flex'
import { LoadingIcon } from '../icons'

interface LoaderProps {
  size?: string | number
  show?: boolean
  portal?: Element | DocumentFragment
}

export function Loader({ show = true, size = 64, portal }: LoaderProps) {
  return show
    ? createPortal(
        <Flex
          align="center"
          className="pointer-events-none fixed left-0 top-0 h-screen w-screen bg-white/80"
          justify="center"
          style={{ zIndex: ZIndexOverlay + 1000 }}
        >
          <LoadingIcon fontSize={size} />
        </Flex>,
        portal ?? document.body
      )
    : null
}
