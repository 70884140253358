'use client'

import {
  type ComponentPropsWithoutRef,
  forwardRef,
  type ReactElement,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import SelectPrimitive, {
  type ClearIndicatorProps,
  components,
  type ControlProps,
  type DropdownIndicatorProps,
  type OptionProps,
} from 'react-select'

import { CloseIcon, OutlinedDownIcon, XMarkIcon } from '@/components/icons'
import { type TextFieldProps, textFieldVariants } from '@/components/input/TextField'
import { tw } from '@/lib'
import {
  ColorBrandDefault,
  ColorBrandHovered,
  ColorInputDefault,
  ColorInputPlaceholder,
  ZIndexModal,
} from '@/lib/constants/design-tokens'

function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null
}

type SelectOption<TValue = any, TOriginalValue = any> = {
  label?: string
  value?: TValue
  returnObject?: TOriginalValue
}

type ReactSelect = typeof SelectPrimitive<SelectOption, boolean>

interface SelectProps<TValue = any>
  extends Omit<
    ComponentPropsWithoutRef<ReactSelect>,
    'defaultValue' | 'value' | 'onChange' | 'options'
  > {
  /**
   * @deprecated
   */
  variant?: TextFieldProps['variant']
  prefixIcon?: ReactNode
  options: SelectOption<TValue>[]
  defaultValue?: TValue | TValue[] | null
  value?: TValue | TValue[] | null
  onChange?: (value: TValue | TValue[] | null, returnObject?: any) => void
  hideMultiValueRemove?: boolean
  density?: TextFieldProps['density']
}

function Control({ children, className, ...props }: ControlProps<SelectOption, boolean>) {
  const { prefixIcon, density } = props.selectProps as unknown as SelectProps
  return (
    <components.Control
      {...props}
      className={tw.merge(
        textFieldVariants({ disabled: props.isDisabled, density }),
        props.selectProps['aria-invalid'] && 'bg-input-error',
        props.isMulti && '!h-auto min-h-input py-2.5'
      )}
    >
      {prefixIcon ? (
        <span
          className={tw.merge(
            'pr-5 text-base',
            density === 'compact' && 'pr-4',
            props.isDisabled || !props.hasValue ? 'text-input-placeholder' : 'text-input'
          )}
        >
          {prefixIcon}
        </span>
      ) : null}
      {children}
    </components.Control>
  )
}

function Option({ className, ...props }: OptionProps<SelectOption, boolean>) {
  return (
    <components.Option
      {...props}
      className={tw.merge(
        'flex items-center text-sm py-2 px-4 min-h-10 hover:bg-gray-100 text-input',
        props.isSelected &&
          'bg-brand text-brand-foreground hover:bg-brand hover:text-brand-foreground',
        props.isDisabled && 'pointer-events-none opacity-50'
      )}
    />
  )
}

function DropdownIndicator(props: DropdownIndicatorProps<SelectOption, boolean>) {
  return (
    <components.DropdownIndicator {...props}>
      <OutlinedDownIcon fontSize={16} />
    </components.DropdownIndicator>
  )
}

function ClearIndicator(props: ClearIndicatorProps<SelectOption, boolean>) {
  return (
    <components.ClearIndicator {...props}>
      <span className="mx-1 flex size-4 items-center justify-center">
        <CloseIcon fontSize={16} />
      </span>
    </components.ClearIndicator>
  )
}

function SelectInner<T = any>(props: SelectProps<T>, ref: any) {
  const {
    isClearable = true,
    closeMenuOnSelect,
    id,
    menuPosition = 'fixed',
    defaultValue,
    value,
    onChange,
    hideMultiValueRemove,
    placeholder = '',
    ...restProps
  } = props

  const [innerValue, setInnerValue] = useState(value)

  const selectValue = useMemo(() => {
    const options = props.options
    if (Array.isArray(innerValue)) {
      return options.filter((opt) => innerValue.includes(opt?.value as T))
    }
    return isDefined(innerValue) ? options.find((opt) => opt.value === innerValue) ?? null : null
  }, [props.options, innerValue])

  const handleChangeValue = (newValue: SelectOption | readonly SelectOption[] | null) => {
    if (Array.isArray(newValue)) {
      const newValues = newValue.map((opt) => opt.value)
      setInnerValue(newValues)
      onChange?.(
        newValues,
        newValue.map((opt) => opt.returnObject)
      )
    } else {
      const newVal = (newValue as SelectOption)?.value
      setInnerValue(newVal)
      onChange?.(newVal, (newValue as SelectOption)?.returnObject)
    }
  }

  useEffect(() => {
    setInnerValue(value ?? defaultValue)
  }, [defaultValue, value])

  return (
    <SelectPrimitive
      {...restProps}
      classNames={{
        container: () => 'flex-1',
      }}
      closeMenuOnSelect={closeMenuOnSelect ?? !restProps.isMulti}
      components={{
        Control,
        Option,
        DropdownIndicator,
        IndicatorSeparator: null,
        MultiValueRemove: hideMultiValueRemove
          ? () => <div className="pl-1" />
          : (props) => (
              <components.MultiValueRemove {...props}>
                <XMarkIcon fontSize={12} />
              </components.MultiValueRemove>
            ),
        ClearIndicator,
        ...restProps.components,
      }}
      inputId={id}
      isClearable={isClearable}
      menuPortalTarget={document.body}
      menuPosition={menuPosition}
      onChange={handleChangeValue}
      placeholder={placeholder}
      ref={ref}
      styles={{
        control: () => ({}),
        option: () => ({}),
        input: (base) => ({
          ...base,
          padding: 0,
          margin: 0,
          flexShrink: 0,
        }),
        multiValue: (base, props) => ({
          ...base,
          backgroundColor: ColorBrandDefault,
          borderRadius: 10,
          color: 'white',
          overflow: 'hidden',
          ...(props.isDisabled ? { backgroundColor: '#333' } : {}),
        }),
        multiValueLabel: (base) => ({
          ...base,
          color: 'white',
          paddingLeft: 8,
          paddingTop: 8,
          paddingBottom: 8,
          fontSize: 16,
          fontWeight: 700,
          ...(props.isDisabled ? { color: '#8B8B8B' } : {}),
        }),
        multiValueRemove: (base) => ({
          ...base,
          paddingRight: 8,
          paddingLeft: 8,
          color: 'white',
          ':hover': {
            backgroundColor: ColorBrandHovered,
            color: 'white',
          },
          ...(props.isDisabled ? { color: '#8B8B8B' } : {}),
        }),
        menuPortal: (base) => ({
          ...base,
          // Should be higher than the modal
          zIndex: ZIndexModal + 10,
          pointerEvents: 'auto',
        }),
        dropdownIndicator: (base, props) => ({
          ...base,
          padding: 0,
          color: props.hasValue && !props.isDisabled ? ColorInputDefault : ColorInputPlaceholder,
          ':hover': {},
        }),
        placeholder: (base) => ({
          ...base,
          color: ColorInputPlaceholder,
          margin: 0,
        }),
        singleValue: (base, props) => ({
          ...base,
          color: props.isDisabled ? ColorInputPlaceholder : ColorInputDefault,
          marginLeft: 0,
        }),
        clearIndicator: (base) => ({
          ...base,
          padding: 0,
          color: ColorInputDefault,
          ':hover': {},
        }),
        valueContainer: (base) => ({
          ...base,
          padding: '0',
          gap: 10,
        }),
      }}
      value={selectValue}
    />
  )
}

const MultiSelect = forwardRef(SelectInner) as <T = any>(
  props: SelectProps<T> & { ref?: any }
) => ReactElement

export { MultiSelect }
