'use client'

import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from 'firebase/auth'

import { useClientTranslation } from '@/app/i18n/client'
import {
  FikaigoTextLogo,
  Flex,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Loader,
} from '@/components'
import { isDevelopmentStage } from '@/config/env'
import { isEmpty } from '@/utils'
import { errorHandler } from '@/utils/errors'

import { useRevokeRefreshToken } from '../hooks'

// ナビゲーションヘッダー
export function NavHeader({ showHoverMenu }: { showHoverMenu: boolean }) {
  return (
    <header className="fixed top-0 z-40 box-border flex h-[var(--h-navheader)] w-screen items-center justify-between border-b border-b-brand bg-gainsboro pl-7.5">
      <FikaigoTextLogo />
      {showHoverMenu && <HoverMenu />}
    </header>
  )
}

function HoverMenu() {
  const [user, loading, error] = useAuthState(getAuth())
  const { t } = useClientTranslation(['common'])
  const { signOut, isPending } = useRevokeRefreshToken(getAuth())

  const handleSignOut = () => {
    signOut()
  }

  if (loading) return null

  if (error) {
    errorHandler({ error: new Error(t('messages.failedToGetUser')) })
  }

  const userName = isEmpty(user?.displayName) ? t('labels.notSet') : user?.displayName

  return (
    <>
      {/* サインアウト時にローディング処理を表示 */}
      {isPending && <Loader />}
      <Flex asChild>
        <nav data-testid="nav-user-info">
          <HoverCard>
            <HoverCardTrigger asChild>
              <div className="flex h-20 w-56 select-none items-center justify-center bg-brand">
                <p className="text-xl font-bold text-white underline">{userName}</p>
              </div>
            </HoverCardTrigger>
            <HoverCardContent>
              <div className="relative flex w-50 flex-col items-start justify-start text-center text-base font-normal text-black">
                {isDevelopmentStage && <DebugUserInfoCardContent />}
                <SignOutCardContent handleSignOut={handleSignOut} />
              </div>
            </HoverCardContent>
          </HoverCard>
        </nav>
      </Flex>
    </>
  )
}

function SignOutCardContent({ handleSignOut }: { handleSignOut: () => void }) {
  const { t } = useClientTranslation(['common'])

  return (
    <div
      className="relative flex h-input w-full items-center justify-center hover:bg-brand hover:text-white"
      data-testid="sign-out-button"
      onClick={handleSignOut}
      role="button"
    >
      {t('menus.signOut')}
    </div>
  )
}

function DebugUserInfoCardContent() {
  const [user, loading, error] = useAuthState(getAuth())

  if (loading) return

  if (error) throw error

  return (
    <div
      className="relative flex h-input w-full items-center justify-center border-b border-dashed border-darkgray hover:bg-brand hover:text-white"
      onClick={async () => {
        const token = (await user?.getIdToken(true)) ?? 'Failed to get user id token.'
        const userInfo = user?.toJSON() ?? 'Failed to get user info.'
        console.log({ token })
        console.log({ userInfo })
      }}
      role="button"
    >
      ユーザー情報表示
    </div>
  )
}
