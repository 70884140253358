import { type IconSvgProps } from './types'

export function XMarkIcon(props: IconSvgProps) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 12 12"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1.33203 1L10.9987 11" stroke="currentColor" strokeWidth="2" />
      <path d="M10.668 1L1.0013 11" stroke="currentColor" strokeWidth="2" />
    </svg>
  )
}
