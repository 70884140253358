'use client'

import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef, useMemo } from 'react'

import { Flex } from '../../components'
import { cn } from '../../lib/index'
import { type NonNullableProps } from '../../lib/types'

export const textFieldVariants = cva(
  'box-border flex h-[3.9375rem] w-full items-center rounded-base border border-input-border bg-white text-base text-input placeholder:text-input-placeholder focus-visible:outline-none aria-[invalid=true]:bg-input-error',
  {
    variants: {
      density: {
        default: 'p-5',
        compact: 'px-4 py-[5px]',
      },
      withPrefixIcon: {
        true: 'pl-14',
      },
      withSuffixIcon: {
        true: 'pr-14',
      },
      disabled: {
        true: 'pointer-events-none bg-input-disabled text-input-placeholder',
      },
    },
    compoundVariants: [
      {
        withPrefixIcon: true,
        density: 'compact',
        className: 'pl-10',
      },
      {
        withSuffixIcon: true,
        density: 'compact',
        className: 'pr-10',
      },
    ],
    defaultVariants: {
      density: 'default',
    },
  }
)

export interface TextFieldProps
  extends NonNullableProps<VariantProps<typeof textFieldVariants>>,
    Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  type?: 'text' | 'email' | 'tel' | 'password' | 'url' | 'search'
  prefixIcon?: React.ReactNode
  suffixIcon?: React.ReactNode
  /**
   * @deprecated
   */
  variant?: 'default' | 'filled' | 'raised'
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ className, type, prefixIcon, suffixIcon, density, ...props }, ref) => {
    const isPlaceholder = useMemo(() => {
      return !!props.placeholder && !props.value
    }, [props.placeholder, props.value])

    return (
      <Flex className="relative" display="flex">
        {prefixIcon ? (
          <span
            className={cn(
              'absolute inset-y-0 left-0 flex items-center pl-5',
              density === 'compact' && 'pl-4',
              isPlaceholder || props.disabled ? 'text-input-placeholder' : 'text-input'
            )}
          >
            {prefixIcon}
          </span>
        ) : null}
        <input
          {...props}
          className={cn(
            textFieldVariants({
              withPrefixIcon: !!prefixIcon,
              withSuffixIcon: !!suffixIcon,
              disabled: props.disabled,
              density,
              className,
            })
          )}
          ref={ref}
          type={type}
        />
        {suffixIcon ? (
          <span
            className={cn(
              'absolute inset-y-0 right-0 flex items-center pr-5',
              density === 'compact' && 'pr-4',
              isPlaceholder || props.disabled ? 'text-input-placeholder' : 'text-input'
            )}
          >
            {suffixIcon}
          </span>
        ) : null}
      </Flex>
    )
  }
)
TextField.displayName = 'TextField'

export { TextField }
