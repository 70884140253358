import { type IconSvgProps } from './types'

export function PrivacyPolicyIcon(props: IconSvgProps) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 16 17"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_92_525)">
        <path
          clipRule="evenodd"
          d="M2 3.38281C4.65897 3.23869 7.0228 2.22978 8.49986 0.5C9.97688 2.22978 12.3407 3.23869 15 3.38281V9.29281C15 13.1848 8.49986 16.5 8.49986 16.5C8.49986 16.5 2 13.1848 2 9.29281V3.38281ZM10.1611 7.9928H10.272C10.6741 7.9928 11 8.32019 11 8.72376V10.769C11 11.1726 10.6741 11.5 10.272 11.5H6.72804C6.32591 11.5 6 11.1726 6 10.769V8.72376C6 8.3202 6.32591 7.9928 6.72804 7.9928H6.83911V7.16773C6.83875 6.70832 7.0255 6.28919 7.32551 5.98853C7.62497 5.68714 8.04243 5.49981 8.50001 5.5C8.95758 5.49981 9.37504 5.68714 9.6745 5.98853C9.97452 6.28919 10.1613 6.70832 10.1611 7.16773V7.9928ZM7.83398 6.49902C7.66309 6.67115 7.55841 6.90534 7.55823 7.16773V7.9928H9.4418V7.16773C9.44161 6.90534 9.33692 6.67115 9.16586 6.49902C8.99442 6.32727 8.76117 6.22217 8.50001 6.22217C8.23884 6.22217 8.00559 6.32727 7.83398 6.49902ZM8.82518 10.7192L8.71046 9.97272C8.89138 9.8916 9.01795 9.70996 9.01796 9.4983C9.01796 9.21119 8.78598 8.97828 8.50002 8.97828C8.21387 8.97828 7.98207 9.21119 7.98207 9.4983C7.98207 9.70996 8.10846 9.8916 8.28937 9.97272L8.17484 10.7192H8.50001H8.82518Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_92_525">
          <rect fill="white" height="16" transform="translate(0 0.5)" width="16" />
        </clipPath>
      </defs>
    </svg>
  )
}
