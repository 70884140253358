'use client'

import { type PropsWithChildren } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from 'firebase/auth'
import { type Middleware } from 'openapi-fetch'

import { client } from '@/lib/api'

// APIリクエスト時のミドルウェア処理
export function RequestMiddlewareWrapper({ children }: PropsWithChildren) {
  const [user, loading, error] = useAuthState(getAuth())

  if (loading) return

  if (error) throw error

  // 各ページでリクエスト時に共通でIDトークンを付与
  const middleware: Middleware = {
    async onRequest({ request }) {
      const token = (await user?.getIdToken(true)) ?? ''
      request.headers.set('Authorization', `Bearer ${token}`)
      return request
    },
  }

  client.use(middleware)

  return children
}
