'use client'

import { forwardRef, type HTMLAttributes } from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn, type NonNullableProps } from '../../lib'

const gridVariants = cva([], {
  variants: {
    display: {
      none: 'hidden',
      grid: 'grid',
      'inline-grid': 'inline-grid',
    },
    cols: {
      '1': 'grid-cols-1',
      '2': 'grid-cols-2',
      '3': 'grid-cols-3',
      '4': 'grid-cols-4',
      '5': 'grid-cols-5',
      '6': 'grid-cols-6',
      '7': 'grid-cols-7',
      '8': 'grid-cols-8',
      '9': 'grid-cols-9',
      '10': 'grid-cols-10',
      '11': 'grid-cols-11',
      '12': 'grid-cols-12',
    },
    span: {
      auto: 'col-auto',
      '1': 'col-span-1',
      '2': 'col-span-2',
      '3': 'col-span-3',
      '4': 'col-span-4',
      '5': 'col-span-5',
      '6': 'col-span-6',
      '7': 'col-span-7',
      '8': 'col-span-8',
      '9': 'col-span-9',
      '10': 'col-span-10',
      '11': 'col-span-11',
      '12': 'col-span-12',
      full: 'col-span-full',
    },
    align: {
      start: 'items-start',
      end: 'items-end',
      center: 'items-center',
      baseline: 'items-baseline',
      stretch: 'items-stretch',
    },
    justify: {
      start: 'justify-start',
      end: 'justify-end',
      center: 'justify-center',
      'space-between': 'justify-between',
      'space-around': 'justify-around',
      'space-evenly': 'justify-evenly',
    },
    gap: {
      '0': 'gap-0',
      '1': 'gap-1',
      '1.5': 'gap-1.5',
      '2': 'gap-2',
      '2.5': 'gap-2.5',
      '3': 'gap-3',
      '3.5': 'gap-3.5',
      '4': 'gap-4',
      '4.5': 'gap-4.5',
      '5': 'gap-5',
      '5.5': 'gap-5.5',
      '6': 'gap-6',
      '6.5': 'gap-6.5',
      '7': 'gap-7',
      '7.5': 'gap-7.5',
      '8': 'gap-8',
      '8.5': 'gap-8.5',
      '9': 'gap-9',
      '9.5': 'gap-9.5',
      '10': 'gap-10',
    },
  },
})

interface GridProps
  extends HTMLAttributes<HTMLDivElement>,
    NonNullableProps<VariantProps<typeof gridVariants>> {
  asChild?: boolean
}

const Grid = forwardRef<HTMLDivElement, GridProps>((props, ref) => {
  const {
    display = 'grid',
    cols,
    span,
    align,
    justify,
    gap,
    className,
    asChild,
    ...restProps
  } = props
  const Comp = asChild ? Slot : 'div'
  return (
    <Comp
      className={cn(
        gridVariants({
          display,
          cols,
          span,
          align,
          justify,
          gap,
        }),
        className
      )}
      ref={ref}
      {...restProps}
    />
  )
})

Grid.displayName = 'Grid'

export { Grid, type GridProps }
