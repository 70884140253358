import { type IconSvgProps } from './types'

export function WarningIcon(props: IconSvgProps) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 83 83"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_187_110066)">
        <path
          d="M40.483 56.0488C37.8226 56.0488 35.6626 58.2068 35.6626 60.8675C35.6626 63.5292 37.8226 65.6874 40.483 65.6874C43.1434 65.6874 45.3012 63.5292 45.3012 60.8675C45.3013 58.2068 43.1435 56.0488 40.483 56.0488Z"
          fill="currentColor"
        />
        <path
          d="M78.9214 64.0344L46.9951 8.08606C45.5572 5.56553 42.877 4 39.9987 4C37.1227 4 34.445 5.56569 33.007 8.08606L1.07845 64.0321C-0.359523 66.5524 -0.359523 69.6838 1.07845 72.2042C2.51657 74.7246 5.19657 76.2891 8.07266 76.2891H71.9273C74.8033 76.2891 77.4834 74.7246 78.9214 72.2042C80.3595 69.6838 80.3595 66.5524 78.9214 64.0344ZM72.5726 68.0318C72.1117 68.8385 71.2533 69.3386 70.3304 69.3386H9.66844C8.74782 69.3386 7.88813 68.8385 7.42954 68.0294C6.96641 67.2216 6.96407 66.2201 7.42735 65.4134L37.7589 12.2584C38.2187 11.4517 39.077 10.9504 40.0011 10.9504C40.923 10.9504 41.7801 11.4517 42.2411 12.2597L72.5726 65.4125C73.0336 66.2201 73.0336 67.224 72.5726 68.0318Z"
          fill="currentColor"
        />
        <path
          d="M40.483 23.2773C37.8226 23.2773 35.6626 25.457 35.6626 28.1456L37.514 50.1589C37.514 51.8154 38.842 53.1569 40.483 53.1569C42.1216 53.1569 43.452 51.8154 43.452 50.1589L45.3012 28.1456C45.3013 25.4568 43.1435 23.2773 40.483 23.2773Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
