'use client'

import { REGEXP_ONLY_DIGITS } from 'input-otp'
import { type Dispatch, type SetStateAction } from 'react'

import { InputOTP as ShadcnInputOTP, InputOTPGroup, InputOTPSlot } from '@/components/ui/input-otp'

export function InputOTP({
  value,
  onChange,
}: {
  value: string
  onChange: Dispatch<SetStateAction<string>>
}) {
  return (
    <ShadcnInputOTP maxLength={6} onChange={onChange} pattern={REGEXP_ONLY_DIGITS} value={value}>
      <InputOTPGroup>
        <InputOTPSlot index={0} />
        <InputOTPSlot index={1} />
        <InputOTPSlot index={2} />
        <InputOTPSlot index={3} />
        <InputOTPSlot index={4} />
        <InputOTPSlot index={5} />
      </InputOTPGroup>
    </ShadcnInputOTP>
  )
}
