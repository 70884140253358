import { type ReactNode } from 'react'

import {
  Tooltip as ShadTooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip'

interface Props {
  open: boolean
  tooltipTrigger: ReactNode
  tooltipContent: ReactNode
}

export function Tooltip({ open, tooltipTrigger, tooltipContent }: Props) {
  return (
    <TooltipProvider>
      <ShadTooltip open={open}>
        <TooltipTrigger>{tooltipTrigger}</TooltipTrigger>
        <TooltipContent>{tooltipContent}</TooltipContent>
      </ShadTooltip>
    </TooltipProvider>
  )
}
