import { type IconSvgProps } from './types'

export function HelpIcon(props: IconSvgProps) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 51 50"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43.2488 7.32207C38.7327 2.80234 32.4668 -0.00136669 25.5715 4.99788e-07C18.6763 -0.00136669 12.4097 2.80234 7.894 7.32207C3.37388 11.8382 0.570167 18.1048 0.571534 25C0.570167 31.8953 3.37388 38.1619 7.894 42.678C12.41 47.1977 18.6763 50.0015 25.5715 50C32.4668 50.0015 38.7327 47.1977 43.2488 42.678C47.7691 38.1619 50.573 31.8953 50.5715 25C50.573 18.1048 47.7692 11.8382 43.2488 7.32207ZM39.5827 10.9889C43.1721 14.5826 45.3849 19.5197 45.3863 25C45.3849 30.4804 43.172 35.4174 39.5827 39.0111C35.9889 42.6012 31.0511 44.8134 25.5715 44.8147C20.0917 44.8134 15.1542 42.6012 11.5604 39.0111C7.97056 35.4174 5.75815 30.4804 5.75669 25C5.75815 19.5197 7.97056 14.5826 11.5604 10.9889C15.1542 7.39873 20.0916 5.18662 25.5715 5.18516C31.0512 5.18672 35.9889 7.39873 39.5827 10.9889Z"
        fill="currentColor"
      />
      <path
        d="M24.2776 32.1608C22.8901 32.1608 21.7649 33.2857 21.7649 34.6731C21.7649 36.059 22.8901 37.1846 24.2776 37.1846C25.6639 37.1846 26.7887 36.059 26.7887 34.6731C26.7887 33.2857 25.6639 32.1608 24.2776 32.1608Z"
        fill="currentColor"
      />
      <path
        d="M18.5757 16.9777L20.2861 18.3485C20.6344 18.6271 21.1332 18.6169 21.4695 18.3239C21.4695 18.3239 21.6797 17.9442 22.3383 17.568C23.0002 17.194 23.8588 16.8931 25.1418 16.8887C26.2611 16.8865 27.2374 17.3039 27.9033 17.8747C28.2339 18.1583 28.4812 18.4736 28.6328 18.7644C28.7854 19.056 28.8411 19.3112 28.8404 19.5051C28.8375 20.1605 28.7099 20.5895 28.5258 20.954C28.3858 21.2275 28.2032 21.4691 27.9677 21.7034C27.6165 22.055 27.1393 22.3798 26.6049 22.6785C26.0695 22.9795 25.4927 23.2457 24.9097 23.5668C24.2448 23.9358 23.5403 24.4647 23.0205 25.2588C22.7608 25.6509 22.5579 26.1008 22.4288 26.5738C22.2985 27.0491 22.2396 27.5461 22.2396 28.0531C22.2396 28.5943 22.2396 29.0391 22.2396 29.0391C22.2396 29.5484 22.6534 29.9629 23.1638 29.9629H25.3891C25.8991 29.9629 26.3126 29.5484 26.3126 29.0391C26.3126 29.0391 26.3126 28.5943 26.3126 28.0531C26.3126 27.8578 26.335 27.7313 26.3567 27.6524C26.3932 27.5323 26.4139 27.5026 26.4747 27.4295C26.5358 27.3601 26.6599 27.2545 26.8885 27.1279C27.2224 26.9405 27.7591 26.6873 28.3671 26.3582C29.2774 25.8606 30.3835 25.1857 31.3167 24.0709C31.7804 23.514 32.1905 22.8447 32.4749 22.0736C32.7599 21.3018 32.9146 20.4338 32.9133 19.5049C32.9125 18.5638 32.6572 17.669 32.2412 16.8754C31.6147 15.6833 30.6331 14.6908 29.4171 13.9703C28.2015 13.2534 26.7345 12.8158 25.142 12.8158C23.1794 12.8108 21.5485 13.3229 20.3182 14.0311C19.0822 14.7363 18.5495 15.5573 18.5495 15.5573C18.3419 15.7382 18.2247 16.0001 18.2294 16.2742C18.2353 16.5495 18.3615 16.807 18.5757 16.9777Z"
        fill="currentColor"
      />
    </svg>
  )
}
