import type { ClassValue } from 'clsx'
import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

const tw = (strings: TemplateStringsArray, ...expressions: any[]) => {
  let finalString = ''

  // Loop through all expressions
  expressions.forEach((value, i) => {
    finalString += `${strings[i]}${value}`
  })

  // Add the last string literal
  finalString += strings[strings.length - 1]

  return finalString
}

tw.merge = (...classes: ClassValue[]) => twMerge(clsx(classes))

export { cn, tw }
