import React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { type NonNullableProps } from '../../lib/types'
import { tw } from '../../lib/utils'

const tagVariants = cva(
  'inline-flex items-center justify-center rounded-base px-3.5 py-1.5 font-bold text-white',
  {
    variants: {
      variant: {
        default: 'bg-brand',
        required: 'bg-darkred',
        info: 'bg-info',
        success: 'bg-success'
      },
      disabled: {
        true: 'pointer-events-none bg-input-disabled text-[#888]',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export interface TagProps
  extends React.HTMLAttributes<HTMLSpanElement>,
   NonNullableProps<VariantProps<typeof tagVariants>> {}

const Tag = React.forwardRef<HTMLSpanElement, TagProps>(
  ({ children, className, variant, disabled, ...props }, ref) => {
    return (
      <span
        className={tw.merge(tagVariants({ variant, disabled }), className)}
        ref={ref}
        {...props}
      >
        {children}
      </span>
    )
  }
)
Tag.displayName = 'Tag'

export { Tag }
