import { type IconSvgProps } from './types'

export function CalendarIcon(props: IconSvgProps) {
  return (
    <svg height="1em" viewBox="0 0 16 17" width="1em" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.56733 3.73516C5.08138 3.73516 5.49818 3.32984 5.49818 2.82975V1.40513C5.49818 0.905313 5.08138 0.5 4.56733 0.5C4.05306 0.5 3.6362 0.905313 3.6362 1.40513V2.82975C3.6362 3.32988 4.05306 3.73516 4.56733 3.73516Z"
        fill="currentColor"
      />
      <path
        d="M11.4332 3.73516C11.9472 3.73516 12.3641 3.32984 12.3641 2.82975V1.40513C12.3641 0.905313 11.9472 0.5 11.4332 0.5C10.9189 0.5 10.5021 0.905313 10.5021 1.40513V2.82975C10.5021 3.32988 10.9189 3.73516 11.4332 3.73516Z"
        fill="currentColor"
      />
      <path
        d="M13.758 1.79028H13.1819V2.82981C13.1819 3.76785 12.3973 4.53081 11.4329 4.53081C10.4682 4.53081 9.68355 3.76785 9.68355 2.82981V1.79028H6.31649V2.82981C6.31649 3.76785 5.53184 4.53081 4.56744 4.53081C3.60279 4.53081 2.81811 3.76785 2.81811 2.82981V1.79028H2.24204C1.00383 1.79028 0 2.76638 0 3.97038V14.32C0 15.524 1.00386 16.5001 2.24204 16.5001H13.758C14.9962 16.5001 16 15.524 16 14.32V3.97038C16 2.76638 14.9962 1.79028 13.758 1.79028ZM14.9091 14.32C14.9091 14.9371 14.3927 15.4393 13.758 15.4393H2.24204C1.60738 15.4393 1.09092 14.9371 1.09092 14.32V5.34813H14.9091V14.32Z"
        fill="currentColor"
      />
      <path d="M7.44412 12.5001H5.42397V14.4645H7.44412V12.5001Z" fill="currentColor" />
      <path d="M7.44412 9.45581H5.42397V11.4202H7.44412V9.45581Z" fill="currentColor" />
      <path d="M4.31443 12.5001H2.29453V14.4645H4.31443V12.5001Z" fill="currentColor" />
      <path d="M4.31443 9.45581H2.29453V11.4202H4.31443V9.45581Z" fill="currentColor" />
      <path d="M13.7053 6.41174H11.6854V8.37584H13.7053V6.41174Z" fill="currentColor" />
      <path d="M10.5759 6.41174H8.55573V8.37584H10.5759V6.41174Z" fill="currentColor" />
      <path d="M10.5759 9.45581H8.55573V11.4202H10.5759V9.45581Z" fill="currentColor" />
      <path d="M13.7053 12.5001H11.6854V14.4645H13.7053V12.5001Z" fill="currentColor" />
      <path d="M13.7053 9.45581H11.6854V11.4202H13.7053V9.45581Z" fill="currentColor" />
      <path d="M10.5759 12.5001H8.55573V14.4645H10.5759V12.5001Z" fill="currentColor" />
      <path d="M7.44412 6.41174H5.42397V8.37584H7.44412V6.41174Z" fill="currentColor" />
      <path d="M4.31443 6.41174H2.29453V8.37584H4.31443V6.41174Z" fill="currentColor" />
    </svg>
  )
}
