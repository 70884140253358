import { type ChangeEventHandler, type ForwardedRef, type HTMLInputTypeAttribute } from 'react'

import { Input as ShadInput } from '@/components/ui/input'
import { cn } from '@/lib/utils'

interface Props {
  placeholder?: string
  disabled?: boolean
  type?: HTMLInputTypeAttribute
  className?: string
  value: string
  onChangeHandler?: ChangeEventHandler<HTMLInputElement>
  readOnly?: boolean
  inputRef?: ForwardedRef<HTMLInputElement>
  id?: string
}

export function Input({
  placeholder,
  type = 'text',
  disabled = false,
  className,
  onChangeHandler,
  value = '',
  readOnly = false,
  inputRef,
  id,
}: Props) {
  return (
    <ShadInput
      className={cn('h-[63px] w-[353px]', className)}
      disabled={disabled}
      id={id}
      onChange={onChangeHandler}
      placeholder={placeholder}
      readOnly={readOnly}
      ref={inputRef}
      type={type}
      value={value}
    />
  )
}
