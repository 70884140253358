'use client'

import { forwardRef, type HTMLAttributes } from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { tw } from '../../lib/index'
import { type NonNullableProps } from '../../lib/types'

const flexVariants = cva([], {
  variants: {
    display: {
      none: 'hidden',
      flex: 'flex',
      'inline-flex': 'inline-flex',
    },
    direction: {
      row: 'flex-row',
      'row-reverse': 'flex-row-reverse',
      column: 'flex-col',
      'column-reverse': 'flex-col-reverse',
    },
    align: {
      start: 'items-start',
      end: 'items-end',
      center: 'items-center',
      baseline: 'items-baseline',
      stretch: 'items-stretch',
    },
    justify: {
      start: 'justify-start',
      end: 'justify-end',
      center: 'justify-center',
      'space-between': 'justify-between',
      'space-around': 'justify-around',
      'space-evenly': 'justify-evenly',
    },
    wrap: {
      wrap: 'flex-wrap',
      nowrap: 'flex-nowrap',
      'wrap-reverse': 'flex-wrap-reverse',
    },
    grow: {
      '0': 'flex-grow-0',
      '1': 'flex-grow',
    },
    shrink: {
      '0': 'flex-shrink-0',
      '1': 'flex-shrink',
    },
    gap: {
      '0': 'gap-0',
      '1': 'gap-1',
      '2': 'gap-2',
      '3': 'gap-3',
      '4': 'gap-4',
      '5': 'gap-5',
      '6': 'gap-6',
      '7': 'gap-7',
      '8': 'gap-8',
      '9': 'gap-9',
      '10': 'gap-10',
    },
  },
})

interface FlexProps
  extends HTMLAttributes<HTMLDivElement>,
    NonNullableProps<VariantProps<typeof flexVariants>> {
  asChild?: boolean
}

const Flex = forwardRef<HTMLDivElement, FlexProps>((props, ref) => {
  const {
    display = 'flex',
    direction,
    align,
    justify,
    wrap,
    shrink,
    grow,
    gap,
    className,
    asChild,
    ...restProps
  } = props
  const Comp = asChild ? Slot : 'div'
  return (
    <Comp
      className={tw.merge(
        flexVariants({
          display,
          direction,
          align,
          justify,
          wrap,
          grow,
          shrink,
          gap,
        }),
        className
      )}
      ref={ref}
      {...restProps}
    />
  )
})

Flex.displayName = 'Flex'

export { Flex, type FlexProps }
