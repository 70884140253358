'use client'

import { useState } from 'react'
import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  type PaginationState,
  useReactTable,
} from '@tanstack/react-table'

import { Button } from '@/components/button'
import { Select, type SelectOption } from '@/components/input/Select'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'

interface TableOptions {
  showPageSize?: boolean
  showPagination?: boolean
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  options?: PaginationState & TableOptions
}

export function DataTable<TData, TValue>({
  columns,
  data,
  options = { pageIndex: 0, pageSize: 10, showPageSize: false, showPagination: false },
}: DataTableProps<TData, TValue>) {
  const {
    pageIndex: optIndex,
    pageSize: optSize,
    showPageSize,
    showPagination: showPagination,
  } = options
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: optIndex,
    pageSize: optSize,
  })

  const table = useReactTable({
    columns,
    data,
    state: { pagination },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    // debugTable: true,
  })

  const pageDisplay =
    data.length === 0
      ? '0 ページ'
      : `${(table.getState().pagination.pageIndex ?? 0) + 1} / ${Number.isNaN(table.getPageCount()) ? 1 : table.getPageCount().toLocaleString()} ページ`

  const isShowPaginationSelectBox = showPageSize && data.length !== 0

  const paginationSelectItems = Array.from(
    { length: Math.floor(data.length / 10) + (data.length % 10 === 0 ? 0 : 1) },
    (_, i) => (i + 1) * 10
  ).map((pageSize) => {
    return { label: `${pageSize}件`, value: pageSize.toString() } as SelectOption
  })

  return (
    <div>
      {/* TODO 色指定を直接行っているので、決まり次第変更する */}
      <div className="rounded-base border border-[#C0C0C0]">
        <Table className="rounded-table">
          {/* ヘッダー */}
          <TableHeader className="rounded-lg">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                className="border-b-[#C0C0C0] first-of-type:rounded-tl-base last-of-type:rounded-tr-base"
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      className="bg-[#6c6c6c] text-xl font-bold text-white first-of-type:rounded-tl-[9px] last-of-type:rounded-tr-[9px]"
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          {/* ヘッダー */}
          {/* ボディ */}
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  className="odd:bg-[#e7e7e7]"
                  data-state={row.getIsSelected() && 'selected'}
                  key={row.id}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="h-24 text-center" colSpan={columns.length}>
                  データがありません。
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {/* ボディ */}
        </Table>
      </div>
      {/* テーブルコントロール */}
      {showPagination && (
        <div className="flex items-center justify-center space-x-2 py-4">
          <div className="flex items-center gap-4">
            <Button
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.firstPage()}
              size="small"
              variant={!table.getCanPreviousPage() ? 'secondary' : 'default'}
            >
              {'<< 最初へ'}
            </Button>
            <Button
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.previousPage()}
              size="small"
              variant={!table.getCanPreviousPage() ? 'secondary' : 'default'}
            >
              {'< 前へ'}
            </Button>

            <p className="font-semibold">{pageDisplay}</p>

            <Button
              disabled={!table.getCanNextPage()}
              onClick={() => table.nextPage()}
              size="small"
              variant={!table.getCanNextPage() ? 'secondary' : 'default'}
            >
              {'次へ >'}
            </Button>
            <Button
              disabled={!table.getCanNextPage()}
              onClick={() => table.lastPage()}
              size="small"
              variant={!table.getCanNextPage() ? 'secondary' : 'default'}
            >
              {'最後へ >>'}
            </Button>

            {isShowPaginationSelectBox && (
              <Select
                className="w-26"
                onValueChange={(value) => {
                  table.setPageSize(Number(value))
                }}
                options={paginationSelectItems}
                value={table.getState().pagination.pageSize.toString()}
              />
            )}
          </div>

          {/* デバッグ用出力 */}
          {/* <pre>{JSON.stringify(table.getState().pagination, null, 0)}</pre> */}
        </div>
      )}
      {/* テーブルコントロール */}
    </div>
  )
}
