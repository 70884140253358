import { type IconSvgProps } from './types'

export function CompanyIcon(props: IconSvgProps) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 16 17"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_92_511)">
        <path
          d="M3 0.5V16.5H7V14H9V16.5H13V0.5H3ZM5.75 10.6665H4.25V8.6665H5.75V10.6665ZM5.75 7.1665H4.25V5.1665H5.75V7.1665ZM5.75 3.6665H4.25V1.6665H5.75V3.6665ZM8.75 10.6665H7.25V8.6665H8.75V10.6665ZM8.75 7.1665H7.25V5.1665H8.75V7.1665ZM8.75 3.6665H7.25V1.6665H8.75V3.6665ZM11.75 10.6665H10.25V8.6665H11.75V10.6665ZM11.75 7.1665H10.25V5.1665H11.75V7.1665ZM11.75 3.6665H10.25V1.6665H11.75V3.6665Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_92_511">
          <rect fill="white" height="16" transform="translate(0 0.5)" width="16" />
        </clipPath>
      </defs>
    </svg>
  )
}
