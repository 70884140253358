'use client'

import { type ExternalToast as ToastOptions, toast } from 'sonner'

import { CheckCircleIcon, WarningIcon } from '@/components/icons'

const createNotifier =
  (type: 'success' | 'info' | 'warning' | 'error', baseOptions?: ToastOptions) =>
  (message: string, options?: ToastOptions) => {
    return toast[type](message, {
      ...baseOptions,
      ...options,
      style: {
        right: '0px',
        padding: '10px 20px',
        display: 'flex',
        width: 'auto',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        fontSize: 24,
        fontWeight: 'bold',
        lineHeight: 'normal',
        borderRadius: '10px',
        ...baseOptions?.style,
        ...options?.style,
      },
    })
  }

const notify = {
  success: createNotifier('success', {
    style: {
      backgroundColor: '#06C',
      color: '#FFF',
    },
    icon: <CheckCircleIcon />,
  }),
  info: createNotifier('info'),
  warning: createNotifier('warning'),
  error: createNotifier('error', {
    style: {
      backgroundColor: '#f00',
      color: '#FFF',
    },
    icon: <WarningIcon />,
  }),
}

export { notify }
